.content {
  .container {
    //margin-left: 300px;
  }
}

.menu-collapsed {
  .content {
    .container {
      margin-left: 80px;
    }
  }

}