.deliveryrequests{
  &__reset{
    left: 50%;
    margin-left: -60px;
  }
  &__refresh-search{
    left: 50%;
    margin-left: 20px;
  }
  &__right{
    .ant-collapse-content-box{
      @include border-radius(2px);
      padding: 20px;
    }
    .deliveryaddress{
      h2{
        position: relative;
        &:before{
          content: "";
          position: absolute;
          width: 20px;
          height: 1px;
          background-color: $bg-color-gray2;
          bottom: 8px;
          left: 0;
        }
      }
    }
    .ant-collapse-item{
      border-bottom: 0;
    }
  }
}

.tablelistpackages{
  table{
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td{
      font-size: $txt-size-h7;
      font-weight: 400;
      color: $txt-color-black;
    }
    .ant-table-tbody tr td{
      padding: 10px 16px;
    }
  }
}
.package-code {
  .ant-checkbox-group-item {
    display: block;
    margin-right: 0;
  }
}

.deliveryaddress {
  padding: 16px;
  border: 1px dashed #D9D9D9;
  @include border-radius(2px);
}

.deliveryfee {
  padding: 16px;
  background-color: #E6F7FF;
  border: 1px solid #91D5FF;
  @include border-radius(2px);
  .ant-divider{
    background-color: #91D5FF;
    margin: 10px 0;
  }
}
.footer-delivery {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: 80px;
  margin-right: 25px;
}