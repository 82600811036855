.nav{
   background: #98D0B9!important;
  ul.ant-menu{
    background: #548570!important;
    li.ant-menu-item{
      background: #ffffff!important;
      margin: 0;
      span, a{
        color: #98D0B9;
      }

    }
  }
}
