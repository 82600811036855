.serviceoption {
  padding: 15px 5px 0 5px;
  &__checkbox {
    margin-left: 0 !important;
    margin-bottom: 10px;
  }
}
.ant-modal-body{
  padding: 20px;
}
.ant-modal-footer{
  border: 0px;
  padding: 0 20px 20px;
}

