.sidebarleft{
	min-height: 100%;
	position: fixed;
	background-color: $bg-color-dark;
	z-index: 99;
	flex: 0 0 280px!important;
	max-width: 280px!important;
	min-width: 280px!important;
	width: 280px!important;
	top: 0;
	bottom:0;
	overflow-y:auto;
	overflow-x:hidden;
	&__logo{
		padding-top: 20px;
		padding-left: 13px;
		margin-right: 5px;
		margin-bottom: 30px;
			&_menu {
				width: 100px;
				border: 0;
			}
			&_collapsed {
				width: 33px;
			}
	}
	&__menu{
		background-color: $bg-color-dark;
		border: 0;
		&--item{
			padding: 0 12px!important;
		}
		li{
			padding-left: 15px!important;
			padding-right: 15px!important;
			.ant-menu-submenu-title{
				padding-left: 0!important;
				i{
					right: 0;
					&:before, &:after{
						background-image: linear-gradient(to right, rgba(225, 225, 225, 1), rgba(225, 225, 225, 1))!important;
					}
				}
			}
			span{
				color: $bg-color-white;
				font-size: $txt-size-h7;
				font-family: $robotofont;
				font-weight: 400;
			}
			i{
				font-size: 17px!important;
				color: $txt-color-white;
			}
		}
		li.ant-menu-item-selected{
			background-color: #0673cc!important;
			&:after{
				width: 0!important;
			}
		}
	}
}

.menu-collapsed{
	.sidebarleft{
		flex: 0 0 56px!important;
		max-width: 56px!important;
		min-width: 56px!important;
		width: 56px!important;
	}
}
.ant-menu-inline-collapsed{
	width: 56px!important;
	.sidebarleft__menu--item{
		padding: 0 19px!important;
	}
	.sidebarleft__menu--item--recharge{
		padding: 0 17px!important;
	}
	.ant-menu-submenu .ant-menu-submenu-title {
		padding-left: 0 !important;
	}
}
.tooltip-menu {

}
.ant-tooltip-placement-right{
	padding-left: 30px;
	.ant-tooltip-arrow {padding-left: 22px}
}
.sp-submenu-finance:hover{
	color: #2c82de !important;
}
.sp-submenu-recharge:hover{
	color: #2c82de !important;
}

.menu-submenu-sidebar {
	.ant-menu-inline.ant-menu-sub {
		background-color: #011d57;
		box-shadow: none;

		.ant-menu-item {
			.childMenu-inline {
				color: #ffff;
				padding-left: 10px;
			}
		}
	}
	.ant-menu-vertical .ant-menu-item .childMenu-vertical{
		color: black;
	}
}

.hide-icon-noChild .ant-menu-submenu-title .ant-menu-submenu-arrow{
	display: none;
}