
.select-status {
  font-family: $robotofont;
  font-weight: 400;
  border: 0;
  .ant-select-selection {
    background-color: transparent;
    border-radius: 50px;
    color: white;
    border: 1px;
    .ant-select-selection-selected-value {
      padding-top: 1px;
      padding-left: 1px;
    }
    .ant-select-arrow .ant-select-arrow-icon {
      color: white;
    }
  }
  .ant-select-selector {
    background: transparent!important;
    border-radius: 50px!important;
    color: white!important;
    border: 1px!important;
    .ant-select-selection-selected-value {
      padding-top: 1px;
      padding-left: 1px;
    }
  }
  .ant-select-arrow{
    color: white!important;
  }
  .ant-select-selection-item{
    text-align: center;
  }
}
.ant-select-selector {
  //border-radius: 4px!important;
}