.boxchat {
  /*position: fixed;
  right: 20px;*/
  //max-height: 500px;
  .ant-list-item {
    padding: 8px 0;
  }
  &__position-fixed {
    position: fixed;
    //right: -0.7rem;
    //bottom: 80px;
    top: 125px;
  }
  &__frame-comment {
    background-color: #ebf7fe;
    padding: 5px;
    border-radius: 3%;
    width: 100%
  }
  .btn-send {
    width: 70px;
    padding: 0 10px;
    text-align: center;
  }
  .btn--blue {
    background: $bg-color-blue;
    height: 30px;
    line-height: 30px;
    width: 70px;
    padding: 0 10px;
    text-align: center;
    @include border-radius(3px);
    &:disabled {
      color: rgba(0, 0, 0, 0.25);
      background-color: #f5f5f5;
      border-color: #d9d9d9;
      text-shadow: none;
      box-shadow: none;
    }
  }
  .ant-btn-loading {
    padding-left: 10px !important;
  }
  &__textarea {
    border-bottom: 0;
    font-size: $txt-size-h7;
    color: $txt-color-black;
    font-family: $robotofont;
    font-weight: 300;
    @include border-radius(0);
    -webkit-border-top-left-radius: 4px;
    -webkit-border-top-right-radius: 4px;
    -moz-border-radius-topleft: 4px;
    -moz-border-radius-topright: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    &:focus {
      outline: none;
      box-shadow: none;
      border: 1px solid rgba(0, 0, 0, 0.14);
      border-bottom: 0;
    }
    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.14);
      border-bottom: 0;
    }
  }
  .ant-tabs-top-bar {
    margin-bottom: 0;
  }

  &__responsiveComment {
    $break-small: 1024px;
    $break-large: 1360px;
    @media screen and (max-width: $break-small) {
      // height: 136px;
    }
    @media screen and (min-width: $break-large) {
      // height: 181px;
    }
    @media screen and (max-width: 1280px) {
      // height: 136px;
    }
  }

  &__content {
    overflow-y: auto;
    height: calc(100% - 90px);
    .ant-list-item-meta-title {
      margin-bottom: 3px;
      font-size: 10px;
      line-height: 1.34;
    }
    &::-webkit-scrollbar-track {
      @include border-radius(20px);
      background-color: #ffffff;
    }
    &::-webkit-scrollbar {
      width: 6px;
      background-color: #ffffff;
    }
    &::-webkit-scrollbar-thumb {
      @include border-radius(20px);
      background-color: $bg-color-gray2;
    }
  }
}

.boxchat-fixed {
  position: fixed;
  right: 26px;
  top: 25px;
}

.modal-frame-view {
  .ant-modal-content {
    .ant-modal-close {
      .ant-modal-close-x {
        margin-top: -17px;
        padding-left: 34px;
      }
    }
  }
}

.tabHeaderLogOrder {
  .ant-tabs-bar .ant-tabs-nav-container .ant-tabs-nav-wrap {
    background-color: #fafafa
  }
}

.list-item-meta-handle .ant-list-item-meta-avatar {
  margin-right: 10px !important;
}

.wpcm {
  height: calc(50% - 64px);
  .boxchat {
    height: 100%;
  }
}
.wpcm-45 {
  height: 45%;
  .boxchat {
    height: 100%;
  }
}
.wpcm-expand {
  height: 100%;
  .boxchat {
    height: 100%;
  }
}
.wpcm-expand-65 {
  height: calc(80% - 157px);
  .boxchat {
    height: 100%;
  }
}

.wpcm-collapse {
  height: 24%;
  .boxchat {
    height: 100%;
  }
}

.cmbt {
  height: 50%;
  .wpcmbt {
    height: 100%;
  }
  .boxchat {
    height: 100%;
  }
}
.boxchat__content{
  height: calc(100% - 90px);
}
.boxchat__content110{
  height: calc(100% - 110px);
}
.boxchat__content150{
  height: calc(100% - 150px);
  .boxchat__content2{
    padding: 0;
    .ant-empty-image{
      height: 60px;
    }
  }
}
.wpcm-expand-65{
  .boxchat__content150{
    .boxchat__content2{
      padding: 20px;
      .ant-empty-image{
        height: 100px;
      }
    }
  }
}
.wpcm-collapse{
  .boxchat{
    .boxchat__content150{display: none}
  }
}
.box-chat-bt10 {
  bottom: 10px !important;
  width:100%
}

.box-chat-bt50 {
  bottom: 50px !important;
  width:100%
}

.box-chat-bt100 {
  width:100%;
  right: -5px;
}

.ant-upload-list-item-card-actions.picture {
  top: 0;
  right: -25px;
}