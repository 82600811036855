h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

#app-main,
#root,
.main-body {
  //height: 100%;
}

.ant-layout {
  //min-height: 100%
}

ul {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
  }
}

.ant-layout-menu {
  height: 100%;

  &::-webkit-scrollbar-track {
    @include border-radius(20px);
    background-color: #ffffff;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    @include border-radius(20px);
    background-color: $bg-color-gray2;
  }
}

//Color

//Color
.txt-color-blue {
  color: $txt-color-blue;
}

.txt-color-grey {
  color: $txt-color-gray;
}

.txt-color-black {
  color: $txt-color-black;
}

.txt-color-black2 {
  color: $txt-color-black2;
}

.txt-color-black3 {
  color: $txt-color-black3;
}

.txt-color-white {
  color: $txt-color-white;
}

.txt-color-gray {
  color: $txt-color-gray;
}

.txt-color-gray2 {
  color: $txt-color-gray2;
}

.txt-color-red {
  color: $txt-color-red;
}

.txt-color-green {
  color: $txt-color-green;
}

.txt-color-orange {
  color: $txt-color-orange;
}

//Font
.robotoregular {
  font-family: $robotofont;
  font-weight: 400;
}

.robotomedium {
  font-family: $robotofont;
  font-weight: 500;
}

.robotobold {
  font-family: $robotofont;
  font-weight: 700;
}

//Background color
.bg-color-gray {
  background-color: $bg-color-gray;
}

.bg-color-gray2 {
  background-color: $bg-color-gray2;
}

.bg-color-gray3 {
  background-color: $bg-color-gray3;
}

.bg-color-gray4 {
  background-color: $bg-color-gray4;
}

.bg-color-white {
  background-color: $bg-color-white !important;
}

.bg-color-green {
  background-color: $bg-color-green;
}

.bg-color-blue {
  background-color: $bg-color-blue;
}

.bg-color-blue2 {
  background-color: $bg-color-blue2;
}

.bg-color-blue3 {
  background-color: $bg-color-blue3;
}

.bg-color-mix-blue {
  background-color: #a6d9f9;
}

//Font-size
.txt-size-h1 {
  font-size: $txt-size-h1;
}

.txt-size-h2 {
  font-size: $txt-size-h2;
}

.txt-size-h3 {
  font-size: $txt-size-h3;
}

.txt-size-h4 {
  font-size: $txt-size-h4;
}

.txt-size-h5 {
  font-size: $txt-size-h5;
}

.txt-size-h7 {
  font-size: $txt-size-h7;
}

.txt-size-h8 {
  font-size: $txt-size-h8;
}

.txt-size-h9 {
  font-size: $txt-size-h9;
}

//Padding
.pd30 {
  padding: 30px;
}

.pd20 {
  padding: 20px;
}

.pd25 {
  padding: 25px;
}

.pd15 {
  padding: 15px;
}

.pd16 {
  padding: 16px;
}

.pd10 {
  padding: 10px;
}

.pd8 {
  padding: 8px;
}

.pd5 {
  padding: 5px;
}

.pd4 {
  padding: 4px;
}

.pdl0 {
  padding-left: 0 !important;
}

.pdl5 {
  padding-left: 5px;
}

.pdl8 {
  padding-left: 8px;
}

.pdl10 {
  padding-left: 10px;
}

.pdl12 {
  padding-left: 12px;
}

.pdl15 {
  padding-left: 15px;
}

.pdl20 {
  padding-left: 20px !important;
}

.pdl24 {
  padding-left: 24px;
}

.pdl25 {
  padding-left: 25px;
}

.pdl30 {
  padding-left: 30px;
}

.pdl35 {
  padding-left: 35px;
}

.pdl40 {
  padding-left: 40px;
}

.pdl45 {
  padding-left: 45px;
}

.pdl50 {
  padding-left: 50px;
}

.pdl55 {
  padding-left: 55px;
}

.pdl65 {
  padding-left: 65px;
}

.pdl60 {
  padding-left: 60px;
}

.pdl70 {
  padding-left: 70px;
}

.pdl80 {
  padding-left: 80px;
}

.pdl95 {
  padding-left: 95px;
}

.pdl100 {
  padding-left: 100px;
}

.pdl200 {
  padding-left: 200px
}

.pdt160 {
  padding-top: 160px;
}

.pdt140 {
  padding-top: 140px;
}

.pdt120 {
  padding-top: 120px;
}

.pdt80 {
  padding-top: 80px;
}

.pdt70 {
  padding-top: 70px;
}

.pdt60 {
  padding-top: 60px;
}

.pdt50 {
  padding-top: 50px;
}

.pdt40 {
  padding-top: 40px;
}

.pdt35 {
  padding-top: 35px;
}

.pdt30 {
  padding-top: 30px;
}

.pdt25 {
  padding-top: 25px;
}

.pdt20 {
  padding-top: 20px;
}

.pdt16 {
  padding-top: 16px;
}

.pdt15 {
  padding-top: 15px;
}

.pdt10 {
  padding-top: 10px;
}

.pdt8 {
  padding-top: 8px;
}

.pdt7 {
  padding-top: 7px;
}

.pdt5 {
  padding-top: 5px;
}

.pdt3 {
  padding-top: 3px;
}

.pdt4 {
  padding-top: 4px;
}

.pdt0 {
  padding-top: 0;
}

.pdr0 {
  padding-right: 0 !important;
}

.pdr3 {
  padding-right: 3px;
}

.pdr5 {
  padding-right: 5px;
}

.pdr8 {
  padding-right: 8px;
}

.pdr10 {
  padding-right: 10px;
}

.pdr15 {
  padding-right: 15px;
}

.pdr20 {
  padding-right: 20px;
}

.pdr24 {
  padding-right: 24px;
}

.pdr25 {
  padding-right: 25px;
}

.pdr30 {
  padding-right: 30px;
}

.pdr40 {
  padding-right: 40px;
}

.pdr45 {
  padding-right: 45px;
}

.pdr50 {
  padding-right: 50px;
}

.pdr60 {
  padding-right: 60px;
}

.pdr80 {
  padding-right: 80px;
}

.pdr100 {
  padding-right: 100px;
}

.pdbt200 {
  padding-bottom: 100px;
}

.pdbt105 {
  padding-bottom: 105px;
}

.pdbt70 {
  padding-bottom: 70px;
}

.pdbt60 {
  padding-bottom: 60px;
}

.pdbt50 {
  padding-bottom: 50px;
}

.pdbt40 {
  padding-bottom: 40px;
}

.pdbt35 {
  padding-bottom: 35px;
}

.pdbt30 {
  padding-bottom: 30px;
}

.pdbt25 {
  padding-bottom: 25px;
}

.pdbt24 {
  padding-bottom: 24px;
}

.pdbt20 {
  padding-bottom: 20px;
}

.pdbt16 {
  padding-bottom: 16px;
}

.pdbt15 {
  padding-bottom: 15px;
}

.pdbt10 {
  padding-bottom: 10px;
}

.pdbt8 {
  padding-bottom: 8px;
}

.pdbt5 {
  padding-bottom: 5px;
}

.pdbt4 {
  padding-bottom: 4px;
}

.pdbt3 {
  padding-bottom: 3px;
}

.pdbt0 {
  padding-bottom: 0px;
}

//Margin

.mgau {
  margin: auto;
}

.mg15 {
  margin: 15px;
}

.mg20 {
  margin: 20px;
}

.mg25 {
  margin: 25px;
}

.mgbt90 {
  margin-bottom: 90px;
}

.mgbt80 {
  margin-bottom: 80px;
}

.mgbt70 {
  margin-bottom: 70px;
}

.mgbt65 {
  margin-bottom: 65px;
}

.mgbt60 {
  margin-bottom: 60px;
}

.mgbt50 {
  margin-bottom: 50px;
}

.mgbt55 {
  margin-bottom: 55px;
}

.mgbt45 {
  margin-bottom: 45px;
}

.mgbt40 {
  margin-bottom: 40px;
}

.mgbt35 {
  margin-bottom: 35px;
}

.mgbt30 {
  margin-bottom: 30px;
}

.mgbt25 {
  margin-bottom: 25px;
}

.mgbt20 {
  margin-bottom: 20px;
}

.mgbt15 {
  margin-bottom: 15px;
}

.mgbt12 {
  margin-bottom: 12px;
}

.mgbt10 {
  margin-bottom: 10px !important;
}

.mgbt5 {
  margin-bottom: 5px;
}

.mgbt3 {
  margin-bottom: 3px;
}

.mgbt0 {
  margin-bottom: 0;
}

.mgl80 {
  margin-left: 80px;
}

.mgl60 {
  margin-left: 60px;
}

.mgl40 {
  margin-left: 40px;
}

.mgl30 {
  margin-left: 30px;
}

.mgl25 {
  margin-left: 25px;
}

.mgl20 {
  margin-left: 20px;
}

.mgl15 {
  margin-left: 15px;
}

.mgl10 {
  margin-left: 10px;
}

.mgl9 {
  margin-left: 9px;
}

.mgl50 {
  margin-left: 50px;
}

.mgl8 {
  margin-left: 8px;
}

.mgl5 {
  margin-left: 5px;
}

.mgl0 {
  margin-left: 0;
}

.mgt5-minus {
  margin-top: -5px;
}

.mgt0 {
  margin-top: 0;
}

.mgt4 {
  margin-top: 4px;
}

.mgt5 {
  margin-top: 5px;
}

.mgt6 {
  margin-top: 6px;
}

.mgt7 {
  margin-top: 7px;
}

.mgt8 {
  margin-top: 8px;
}

.mgt9 {
  margin-top: 9px;
}

.mgt10 {
  margin-top: 10px;
}

.mgt12 {
  margin-top: 12px;
}

.mgt15 {
  margin-top: 15px;
}

.mgt18 {
  margin-top: 18px;
}

.mgt20 {
  margin-top: 20px;
}

.mgt25 {
  margin-top: 25px;
}

.mgt26 {
  margin-top: 26px;
}

.mgt30 {
  margin-top: 30px;
}

.mgt35 {
  margin-top: 35px;
}

.mgt39 {
  margin-top: 39px;
}

.mgt40 {
  margin-top: 40px;
}

.mgt45 {
  margin-top: 45px;
}

.mgt50 {
  margin-top: 50px;
}

.mgt53 {
  margin-top: 53px;
}

.mgt60 {
  margin-top: 60px;
}

.mgt70 {
  margin-top: 70px;
}

.mgr5 {
  margin-right: 5px;
}

.mgr10 {
  margin-right: 10px;
}

.mgr15 {
  margin-right: 15px;
}

.mgr20 {
  margin-right: 20px;
}

.mgr25 {
  margin-right: 25px;
}

.mgr30 {
  margin-right: 30px;
}

.mgr35 {
  margin-right: 35px;
}

.mgr40 {
  margin-right: 40px;
}

.mgr45 {
  margin-right: 45px;
}

.mgr50 {
  margin-right: 50px;
}

.mgr55 {
  margin-right: 55px;
}

.mgr85 {
  margin-right: 85px;
}

.mgr-10 {
  margin-right: -10px;
}

//Line height
.line-height134 {
  line-height: 1.34;
}

.line-height167 {
  line-height: 1.67;
}

//Border-color
.border-bottom-0x {
  border-bottom: 0 !important;
}

.border-bottom-1x {
  border-bottom: 1px solid;
}

.border-bottom-1x-grey {
  border-bottom: 1px solid #e8e8e8;
}

.border-bottom-2x {
  border-bottom: 2px solid;
}

.border-top-1x {
  border-top: 1px solid;
}

.border-top-2x {
  border-top: 2px solid;
}

.border-right-1x {
  border-right: 1px solid;
}

.border-left-1x {
  border-left: 1px solid;
}

.bd0 {
  border: 0px !important;
}

.bd1px {
  border: 1px solid;
}

.borderdotted-bootom-2x {
  border-bottom: 2px dotted;
}

.bdbt0 {
  border-bottom: 0;
}

.bdt0 {
  border-top: 0;
}

.border-top-3x {
  border-top: 3px solid;
}

.bd-color-black {
  border-color: #2f3439;
}

.bd-color-gray {
  border-color: rgba(0, 0, 0, 0.14);
}

.bd-color-gray2 {
  border-color: rgba(0, 0, 0, 0.25);
}

.bd-color-gray4 {
  border-color: #ebe5e5;
}

.bd-color-blue {
  border-color: #1890ff;
}

.bd-color-green {
  border-color: #98D0B9 !important;
}

.bd-color-red {
  border-color: red;
}

.bd-color-pink {
  border-color: #D1A3B9;
}

.bd-color-white {
  border-color: white;
}

.bd-color-mix-blue {
  border-color: #4673c1;
}

//Position
.position-re {
  position: relative;
}

.position-ab {
  position: absolute;
}

.position-fix {
  position: fixed !important;
}

//z-index
.zindex0 {
  z-index: 0;
}

.zindex9 {
  z-index: 9;
}

.zindex99 {
  z-index: 99;
}

.zindex999 {
  z-index: 999;
}

.zindex9999 {
  z-index: 9999;
}

//Float
.fll {
  float: left;
}

.flr {
  float: right;
}

.clearboth {
  clear: both;
}

// Transformation
.txt-uppercase {
  text-transform: uppercase;
}

.txt-lowercase {
  text-transform: lowercase;
}

.txt-capitalize {
  text-transform: capitalize !important;
}

.txt-transform-none {
  text-transform: none !important;
}

//Decoration
.txt-underline {
  text-decoration: underline;
}

.txt-linethrough {
  text-decoration: line-through;
}

.txt-overline {
  text-decoration: overline;
}

// Alignment
.txt-left {
  text-align: left;
}

.txt-right {
  text-align: right !important;
}

.txt-center {
  text-align: center;
}

.txt-justify {
  text-align: justify;
}

.txt-nowrap {
  white-space: nowrap;
}

.txt-initial {
  white-space: initial;
}

//Display
.dpl-none {
  display: none;
}

.dpl-block {
  display: block !important;
}

.dpl-il-block {
  display: inline-block;
}

.dpl-flex {
  display: flex;
}

.dpl-none-mobile {
  display: block;
}

.dpl-none-pc {
  display: none;
}

//Flex
.justify-content-between {
  justify-content: space-between;
}

.justify-content-start {
  justify-content: start;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-direction-row {
  flex-direction: row;
}

.alignitemscenter {
  align-items: center;
}

.alignitemsend {
  align-items: flex-end;
}

//Overflow
.overflow-hidden {
  overflow: hidden;
}

.overflowy-hidden {
  overflow-y: hidden;
}

.overflow-auto {
  overflow: auto;
}

.overflowXscroll {
  overflow-x: scroll;
}

.overflowYscroll {
  overflow-y: scroll;
}

.wordbreakall {
  word-break: break-all;
}

.wordbreakword {
  word-break: break-word;
}

.cursor-pointer {
  cursor: pointer;
}

.text-underline {
  text-decoration: underline;
}

.cursor-nodrop {
  cursor: no-drop;
}

.whitespace {
  white-space: nowrap;
}

.whitespace-initial {
  white-space: initial !important;
}

.whitespace-inherit {
  white-space: inherit !important;
}

.opacity6 {
  opacity: 0.6;
}

.bottom0 {
  bottom: 0;
}

//Border-radius
.border-radius2 {
  @include border-radius(2px);
}

.border-radius4 {
  @include border-radius(4px);
}

.bdr0 {
  border-right: 0;
}

.width30 {
  width: 30%;
}

.width70 {
  width: 70%;
}

.width100px {
  width: 100px;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.imgthumb32 {
  width: 32px;
  height: 32px;
}

.imgthumb14 {
  width: 14px;
  height: 14px;
}

.imgthumb120 {
  width: 120px;
  height: 120px;
}

.ant-avatar {
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.width100 {
  width: 100%;
}

.width50 {
  width: 50%;
}

.width25 {
  width: 25%;
}

.min-width-135 {
  min-width: 135px;
}

.min-width-150 {
  min-width: 150px;
}

.min-width-200 {
  min-width: 200px;
}

.height100 {
  height: 100%;
}

.width250px {
  width: 250px;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-initial {
  cursor: initial;
}

.font-italic {
  font-style: italic;
}

.container {
  margin-left: 80px;
}

.menu-collapsed {
  .container {
    margin-left: 80px;
  }
}

.shadow-bottom {
  @include box-shadow(0, 3px, 5px, 0, rgba(0, 21, 41, 0.05));
}

.titletop {
  h2 {
    font-size: $txt-size-h1;
    color: $txt-color-black;
    font-weight: 400;
    text-transform: uppercase;

    span {
      font-size: 12px;
      color: $txt-color-blue;
      text-transform: capitalize;
    }
  }
}

.steps {
  .ant-steps-item-wait,
  .ant-steps-item-finish {
    .ant-steps-item-icon {
      background-color: transparent;
    }
  }
}

.ant-input-number {
  &.has-warning {
    border-color: $txt-color-orange;

    &:focus {
      box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
    }
  }

  &.has-error {
    border-color: $txt-color-red;

    &:focus {
      box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
    }
  }
}

.txt-error,
.ant-form-explain {
  color: $txt-color-red;
  font-size: $txt-size-h8;
  font-weight: 400;
}

.txt-warning {
  color: $txt-color-orange;
  font-size: $txt-size-h8;
  font-weight: 400;
}

.wpsearch {
  width: 330px;

  &__ic {
    right: 10px;
    top: 8px;
  }
}

textarea {
  resize: none;
}

.orderfixed {
  width: calc(100% - 240px);
  background-color: $bg-color-white;
  bottom: 0;
  height: 60px;
  line-height: 60px;

  &__left {
    width: 72%;
  }

  &__right {
    width: 28%;
    background-color: #e8e8e8;
  }
}

.is-tablet {
  .orderfixed {
    width: calc(100% - 95px);
  }
}

.txtellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  height: 60px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.showmore {
  border: 1px solid #e9e9e9;
  padding: 5px;
  border-top: 0;
  font-size: $txt-size-h7;
  color: $txt-color-blue;
  font-family: $robotofont;
  font-weight: 400;
}

.loadingcenter {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

//.ant-timeline-item-content{
//	top: -2px;
//}
//.ant-timeline-item-tail{
//	top: -2px;
//}
//.ant-timeline-item-last{
//	.ant-timeline-item-content{
//		top: 3px;
//	}
//}
.ant-timeline-item-left {
  .ant-timeline-item-content {
    margin-left: 36px !important;
  }
}

.ant-timeline-item-right {
  .ant-timeline-item-content {
    left: -50px !important;
  }
}

//
//.ant-timeline-item-head{
//	width: 16px;
//	height: 16px;
//	margin-left: -7px!important;
//}
.ic__clockcircle {
  margin-left: 4px;
}

.avatarBorder {
  border: #898989 solid 2px;
  border-radius: 15%;
}

.taglist {
  background-color: #efecec;
  line-height: 24px;
  height: 24px;
  padding: 0 7px;
  display: inline-block;
  @include border-radius(4px);

  &:hover {
    background-color: #e6f7ff;
  }
}

.tag-color-gray {
  position: relative;
  background-color: #e8e8e8;
  min-height: 24px;
  line-height: 24px;
  @include border-radius(3px);
  display: inline-block;
  color: $txt-color-black;
  font-size: $txt-size-h7;
  font-family: $robotofont;
  font-weight: 400;
  padding: 0 25px 0 10px;
  margin-right: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  word-break: break-all;

  i.anticon-close {
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: $txt-size-h7;
    color: $txt-color-gray;
  }

  &:hover {
    background-color: $bg-color-blue;
    color: $txt-color-white;

    i.anticon-close {
      color: $txt-color-white;
    }
  }
}

.has-error {
  border-color: $txt-color-red !important;

  &:focus {
    box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
  }
}

.tableresponsiveDeliveryFixed {
  $break-small: 1024px;
  $break-large: 1425px;
  @media screen and (max-width: $break-small) {
    width: 89.7%;
    float: none;
  }
  @media screen and (min-width: $break-large) {
    width: 92.6%;
    float: right;
  }
}

.tableresponsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar-track {
    @include border-radius(20px);
    background-color: #ffffff;
  }

  &::-webkit-scrollbar {
    //width: 6px;
    height: 6px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    @include border-radius(20px);
    background-color: $bg-color-gray2;
  }
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  white-space: nowrap;
  //padding: 8px 16px;
}

.table__name {
  width: 160px;
  white-space: inherit !important;
}

.packagefilterrefesh {
  height: 20px;
}

.customersform {
  min-height: 32px;
  line-height: 32px;
  margin-bottom: 10px;
}

.ordertop__search_action {
  .ant-select-selection-selected-value {
    color: $txt-color-gray2;
  }
}

/*.ant-select-selection{
	&--single{
		height: 24px;
		.ant-select-selection__rendered{
			line-height: 24px;
		}
	}
}*/
input {
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: $txt-size-h7;
    font-family: $robotofont;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: pink;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: pink;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: pink;
  }
}

.ant-list-split .ant-list-item {
  border-bottom: 1px solid #e8e8e8 !important;
}

.packagerowadd {
  > td {
    padding-top: 15px !important;
  }
}

.add-packageweight {
  min-width: 340px;
}

//hover
.tagHover:hover {
  cursor: pointer;
  color: #1890ff !important;
}

.ant-tag-blue {
  color: #1890ff;
  background-color: transparent;
  border-color: #1890ff;
}

.ant-tabs-nav {
  .ant-tabs-tab-active {
    > div.robotomedium {
      color: $txt-color-blue;
    }
  }
}

.ipcomment {
  border-right: 0;
  @include border-radius(0);
  -webkit-border-top-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.btncomment {
  @include border-radius(0);
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

span.ant-table-header-column {
  display: block !important;
}

.ant-table-fixed-left {
  width: 100%;

  .ant-table-fixed {
    width: 100%;
  }
}

.select-address-connect {
  width: 330px;
}

.customer-balance {
  .ant-table-column-sorters {
    display: inline !important;
  }
}

.ant-upload-list {
  display: flex;
  flex-wrap: wrap;
}

.upload-list-inline .ant-upload-list-item {
  margin-right: 25px !important;
}

.customerfinancial {
  .anticon-info-circle {
  }
}

tr {
  &:first-child {
    .linedg {
      &:before {
        content: '';
        width: 185px;
        height: 47px;
        border-bottom: 1px solid #eae7e7;
        -webkit-transform: translateY(-34px) translateX(129px) rotate(17deg);
        position: absolute;
        right: 115px;
      }
    }
  }
}

.ant-table-thead > tr > th {
  border: 0 !important;
  //background: #f9f5f7!important;
}

.ant-table-tbody > tr > td {
  a {
    color: #98D0B9;
  }

  img {
    border: 1px solid #dddddd;
    @include border-radius('5px')
  }
}

.ant-table-pagination.ant-pagination {
  padding-right: 20px !important;
}

@media only screen and(max-width: 1024px) {
  .select-address-connect {
    width: 175px;
  }
  .customerfinancial {
    span.txt-size-h5 {
      font-size: 12px;
      padding-top: 5px;
    }
  }
}

.ant-upload-list-item-name-icon-count-1 {
  display: block !important;
}

.a-logo {
  align-items: center;
  justify-content: flex-start;
  margin: 0.7rem .6rem;
}

.txt-two-line {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  height: 2.8em;
  line-height: 1.4em;
  display: flex;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
