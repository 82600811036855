.cartscontainer{
	.ant-list-header{padding-bottom: 0}
	.headerlist{
		&__top{
			margin-bottom: 10px;
			//&_right{
			//	padding-top: 10px;
			//}
		}
		&__title{
			height: 40px;
			line-height: 40px;
			border: 1px solid #E9E9E9;
			border-bottom: 0;
			padding: 0 10px;
			background-color: $bg-color-gray;
		}
	}
	.ant-list-empty-text {
		display: none;
	}
	.ant-list-item{
		padding: 15px 10px;
		background-color: $bg-color-white;
		border: 1px solid #E9E9E9;
		.btn__delete{
			display: none;
		}
		&:hover{
			.btn__delete{display: block;}
			border: 1px solid #1890FF!important;
		}
		&.list-alert-error {
			padding: 5px 17px;
			background: #fffbe6;
			border-color: $txt-color-orange!important;
		}
	}
	.ant-spin-container{
		//border: 1px solid #E9E9E9;
    	//border-top: 0;
	}
}
.is-tablet{
	.ipcustom{
		input.ant-input-number-input{
			width: 70px; 
		}
	}
}

.listcards{
	&__item{
		&:last-child{
			border: 1px solid $bg-color-gray!important;
		}
	}
}

.packagelist{
	.ant-table-tbody > tr > td{
		padding: 7px 16px;
	}
	.ant-table-thead > tr > th{
		padding: 10px 16px;
	}
	&__status{
		&_dot{
			.ant-scroll-number{
				top: 3px;
				left: -3px;
			}
		}
	}
}
.tablefinance{
	.ant-table-thead > tr > th, .ant-table-tbody > tr > td{
		padding: 8px 10px;
	}
	.handleamount{
		padding-right: 30px!important;
		.questioncircle{
			top: 1px;
		}
	}
}

.customeraddressfullname{
	width: 200px;
}
.customeraddressdetail{
	width: 300px;
}
.staffrow{
	td{
		white-space: inherit!important;
		word-break: break-all;
	}
	&__fullname{
		width: 300px;
	}
	&__createdby{
		width: 200px;
	}
}


.listdeal{
	.ant-table-thead > tr > th{
		padding: 9px 16px 6px;
		span{
			font-size: $txt-size-h7;
			font-family: $robotofont;
			font-weight: 400;
		}
	}
	&__financialamount{
		width: 125px;
	}
}
.ant-table-thead > tr > th{
	span{
		font-family: $robotofont;
		font-weight: 500;
		font-size: $txt-size-h7;
	}
}