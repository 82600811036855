//Font Family
$robotofont: 'Roboto', sans-serif;

//Font Size
$txt-size-h1: 24px;
$txt-size-h2: 22px;
$txt-size-h3: 20px;
$txt-size-h4: 18px;
$txt-size-h5: 16px;
$txt-size-h6: 15px;
$txt-size-h7: 14px;
$txt-size-h8: 12px;
$txt-size-h9: 10px;

//Color
$txt-color-white: #ffffff;
$txt-color-black: rgba(0,0,0,0.85);
$txt-color-black2: #000000;
$txt-color-black3: rgba(0,0,0,0.65);
$txt-color-gray: rgba(0,0,0,0.45);
$txt-color-gray2: rgba(0,0,0,0.25);
$txt-color-red: #F5222D;
$txt-color-orange: #faad14;
$txt-color-blue: #98D0B9;
$txt-color-green: #7CB305;

//Background Color
$bg-color-white: #ffffff;
$bg-color-yellow: #FFFBE6;
$bg-color-blue: #98D0B9;
$bg-color-blue2: #E6F7FF;
$bg-color-blue3: #A6DBFF;
$bg-color-gray: #f0f2f5;
$bg-color-gray2: #D9D9D9;
$bg-color-gray4: #FAFAFA;
$bg-color-dark: #011d57;
$bg-color-green: #7CB305;
$bg-color-black: #000000;
$bg-color-gray3: #eaebec;
//Border Color
