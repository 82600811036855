.financial_excess {
  background-color: #096dd9;
  min-height: 200px;
  overflow: hidden;
  position: relative;
  .round1 {
    border-radius: 50%;
    width: 182px;
    height: 200px;
    background-color: #2c82de;
    opacity: 0.5;
    position: absolute;
    top: -80px;
    right: -90px;
  }
  .round2 {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    background-color: #2c82de;
    opacity: 0.5;
    position: absolute;
    bottom: -80px;
    right: -76px;
    border: 1px solid  #2c82de;
    overflow: hidden;
    z-index: 999;
    .round2-1 {
      border-radius: 50%;
      width: 182px;
      height: 200px;
      background-color: #9ab2ff;
      position: absolute;
      top: -157px;
      right: -20px;
    }
  }
}

.select-customer-info{
  font-family: $robotofont;
  font-weight: 400;
  border: 0;
  .ant-select-selection {
    background-color: transparent;
    border-radius: 50px !important;
    color: white;
    border: 1px;
    .ant-select-selection-selected-value{
      padding-top: 1px;
      padding-left: 1px;
    }
    .ant-select-arrow .ant-select-arrow-icon{
      color: white;
    }
  }
  .ant-select-selector {
    background: transparent!important;
    border-radius: 50px!important;
    color: white!important;
    border: 1px!important;
    .ant-select-selection-selected-value {
      padding-top: 1px;
      padding-left: 1px;
    }
  }
  .ant-select-arrow{
    color: white!important;
  }
}

.radio-group-vip{
  .ant-form-item{margin-bottom: 10px}
}