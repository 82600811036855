.ordertop{
	&__search{
		.ant-form-item{
			margin-bottom: 0;
		}
		&_action{
			.ant-select-selection{
				position: relative;
				border-right: 0;
				@include border-radius(0);
				-webkit-border-top-left-radius: 4px;
				-webkit-border-bottom-left-radius: 4px;
				-moz-border-radius-topleft: 4px;
				-moz-border-radius-bottomleft: 4px;
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
				&:before{
					content: '';
					position: absolute;
					background-color: $bg-color-gray2;
					width: 1px;
					height: 20px;
					top: 5px;
					right: 0;
				}
			}
		}
		&_datetime{
			.ant-calendar-picker{width: 100%}
			.ant-calendar-picker-input{
				border-left: 0;
				@include border-radius(0);
				-webkit-border-top-right-radius: 4px;
				-webkit-border-bottom-right-radius: 4px;
				-moz-border-radius-topright: 4px;
				-moz-border-radius-bottomright: 4px;
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
			}
		}
		&_tag{
			&-list{
				height: 20px;
				@include border-radius(3px);
				box-shadow: none;
			}
			&-listactive{
				background-color: $bg-color-blue;
				color: $txt-color-white!important;
				span{
					color: $txt-color-white!important;
				}
				&:focus{
					background-color: $bg-color-blue;
					color: $txt-color-white!important;
				}
			}
		}
		.ant-btn-primary{
			background-color: $bg-color-blue;
			color: $txt-color-white!important;
			span{
				color: $txt-color-white!important;
			}
			&:focus{
				background-color: $bg-color-blue;
				color: $txt-color-white!important;
			}
			&:hover{
				background-color: $bg-color-white;
				border: 1px solid $bg-color-blue;
				span{
					color: $txt-color-blue!important;
				}
			}
		}
		&_services{
			margin-bottom: 0;
			&-group{
				.ant-checkbox-wrapper{
					margin-left: 0px!important;
					margin-bottom: 7px;
					margin-right: 10px;
					&:last-child{
						&:before{
							content: '';
							position: absolute;
							width: 1px;
							height: 18px;
							top: 2px;
							right: -25px;
							background-color: #D9D9D9;
						}
					}
				}
				&:last-child{
					.ant-checkbox-wrapper{
						&:before{
							background-color: transparent;
						}
					}
				}
			}
		}
		&_times{
			&:hover{
				.ant-select-selection--single, .ant-calendar-picker-input{
					border-color: #d9d9d9;
					border-right-width: 1px !important;
				}
			}
			&:focus{
				.ant-select-selection--single, .ant-calendar-picker-input, .ant-calendar-picker{
					border-color: #d9d9d9!important;
					border-right-width: 1px !important;
					box-shadow: 0 0!important;
				}
			}
			.ant-select-selection--single, .ant-calendar-picker-input, .ant-calendar-picker{
				&:focus{
					box-shadow: 0 0!important;
					border-color: #d9d9d9!important;
					border-right-width: 1px !important;
				}
			}
			.ant-calendar-picker, .ant-calendar-picker-input{
				&:hover{
					box-shadow: 0 0;
					border-color: #d9d9d9!important;
					border-right-width: 1px !important;
				}
			}
		}
	}
	.ant-divider-dashed{
		margin-top: 15px;
		margin-bottom: 5px
	}
	.ant-form-item-label{
		//line-height: 1.34;
	}
	label{
		font-size: $txt-size-h7;
		color: $txt-color-black2;
		font-family: $robotofont;
		font-weight: 400;
	}
}
.orderlist{
	&__footer{
		position: relative;
		padding: 15px;
		&--expand{
			right: 10px;
			top: 17px;
		}
	}
}
.drawer-package-detail .ant-drawer-body {
	padding-top: 0px;
	padding-left: 0px;
	padding-right: 0;
}
.class-form-item .ant-form-item-control-wrapper {
	.show-help-leave{width: 150px}
	.has-error .ant-form-explain {
		width: 150px
	}
}


.datepick-text {
	position: relative;
	.ant-calendar-picker {
		visibility: hidden;
		position: absolute;
		left: 0;
		top: 35px
	}
}

.order-log-list {
	overflow-y: scroll;
	max-height: 455px;
	padding-top: 12px;
	&::-webkit-scrollbar-track{
		@include border-radius(20px);
		background-color: #ffffff;}

	&::-webkit-scrollbar{
		width: 6px;
		background-color: #ffffff;}

	&::-webkit-scrollbar-thumb{
		@include border-radius(20px);
		background-color: $bg-color-gray2;}

}