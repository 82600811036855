.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 40px;
  height: 40px;
  margin-right: 8px;

  //.ant-upload-list-item-name {
  //  display: none;
  //}

  .ant-upload-list-item-thumbnail {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.upload-list-inline .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}

.upload-list-inline .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}
.upload-line .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  .ant-upload-list-item-icon {
    width: 24px;
    height: 24px;

    svg {
      display: none;
    }
  }
}

.upload-list-inline
  .ant-upload-list-item
  .ant-upload-list-item-info
  .anticon-picture {
  width: 24px;
  height: 24px;
  display: block;
  margin-top: 7px;
  margin-left: 7px;

  svg {
    display: none;
  }
}
.ant-upload-list-item {
  .anticon-close {
    width: 18px;
    height: 18px;
    color: $txt-color-white !important;
    top: -9px !important;
    right: -8px !important;
    background-color: $bg-color-black;
    @include border-radius(100%);
    svg {
      padding-top: 2px;
      padding-right: 0px;
      font-size: 15px;
    }
  }
}
.ant-upload-list-item-name.ant-upload-list-item-name-icon-count-1 {
  display: none;
}
.ant-upload-list .ant-upload-list-picture {
  display: flex !important;
}
