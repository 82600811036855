.formeditaddress{
	.ant-form-item{
		margin-bottom: 10px;
		textarea{
			margin-bottom: 0;
		}
	}
	.ant-form-item-control{
		
	}
}
.modalform{
	.ant-modal-close{
		right: 9px;
		top: 10px;
	}
	.ant-modal-content{
		padding-bottom: 15px;
		.ant-modal-header{
			border: 0;
			padding-top: 30px;
			.ant-modal-title{
				font-size: $txt-size-h1;
				font-weight: 400;
				color: $txt-color-black;
				text-transform: uppercase;
				a{
					text-transform: capitalize;
				}
			}
		}
		.ant-modal-body{
			padding-top: 10px;
			padding-bottom: 0;
		}
		.ant-modal-footer{
			padding: 10px 24px;
			border: 0;
			.ant-btn{
				padding: 0 25px!important;
				height: 40px;
				line-height: 40px;
				font-size: $txt-size-h7;
				font-family: $robotofont;
				font-weight: 700;
				color: $txt-color-gray2;
				&:disabled{
					color: $txt-color-gray2;
				}
			}
			.ant-btn-primary{
				color: $txt-color-white;
			}
		}
		.ant-table-body{
			border: 1px solid #E9E9E9;
			border-bottom: 0;
			@include border-radius(4px);
			.ant-table-thead{
				tr{
					th{
						font-size: 14px;
						color: #9E9E9E;
						font-family: $robotofont;
						font-weight: 400;
					}
				}
			}
		}
	}
}
.modaleditorder{
	.ant-modal-footer{
		text-align: left;
		.ant-btn{
			text-transform: uppercase;
		}
		.btn--blue{
			&:hover{
				color: $txt-color-blue;
			}
		}
	}
	.ant-input-group-addon{
		background-color: transparent;
		color: $txt-color-blue;
		font-size: $txt-size-h5;
		padding-top: 3px;
	}
	.ant-input{
		border-right: 0;
	}
}

.modalformfinance{
	.ant-form-item{margin-bottom: 0}
	label{
		font-size: $txt-size-h7;
		color: $txt-color-black;
		font-family: $robotofont;
		font-weight: 600;
	}
	.ant-modal-content{
		.ant-modal-body{
			padding: 24px;
			padding-top: 0;
		}
		.ant-modal-footer{
			text-align: left;
			padding-top: 0;
		}
	}
}
.dealcreateform{
	.ant-form-item{
		margin-bottom: 5px;
	}
	label{
		font-size: 14px;
		font-weight: 600;
		color: $txt-color-black;
	}
	.width30{
		position: relative;
		&:before{
			content: "";
			position: absolute;
			width: 1px;
			height: 85%;
			background-color: rgba(0,0,0,.15);
			right: 0;
		}
	}
}
.transactionsselect{
	.ant-select{
		width: 32%;
		.ant-select-selection{
			border-right: 0;
			@include border-radius(0);
			-webkit-border-top-left-radius: 4px;
			-webkit-border-bottom-left-radius: 4px;
			-moz-border-radius-topleft: 4px;
			-moz-border-radius-bottomleft: 4px;
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
            &:before{
              content:"";
              position: absolute;
              width: 1px;
              height: 15px;
              background-color: $bg-color-gray2;
              right: 0;
              top: 8px;
            }
          .ant-select-selection-selected-value{
            color: $txt-color-gray;
            font-size: 14px;
          }
		}

	}
	&__ip{
		width: 68%;
		input{
			border-left: 0;
			@include border-radius(0);
			-webkit-border-top-right-radius: 4px;
			-webkit-border-bottom-right-radius: 4px;
			-moz-border-radius-topright: 4px;
			-moz-border-radius-bottomright: 4px;
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
		}
	}
	&__ip2{
		width: 70%;
        input.nthchild1{
          border-left: 0;
          border-right: 0;
          @include border-radius(0);
          text-align: center;
        }
        input.nthchild2{
          border-left: 0;
          border-right: 0;
          @include border-radius(0);
        }
        input.nthchild3{
          border-left: 1px;
          @include border-radius(0);
          text-align: center;
          -webkit-border-top-right-radius: 4px;
          -webkit-border-bottom-right-radius: 4px;
          -moz-border-radius-topright: 4px;
          -moz-border-radius-bottomright: 4px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
	}
}