.btn {
  &--transparent {
    background-color: transparent;
    color: #9E9E9E;
  }

  &--white {
    height: 32px;
    //line-height: 32px;
    background-color: $bg-color-white;
    color: $txt-color-blue !important;
    font-size: $txt-size-h7;
    font-weight: 400;
    border: 1px solid #1890ff;
    @include border-radius(4px);

    &:hover {
      background-color: $bg-color-blue;
      border: 1px solid #1890ff;
      color: $txt-color-white !important;
    }
  }

  &__delete {
    width: 30px;
    height: 30px;
    border: 1px solid #D9D9D9;
    color: #898989;
    font-size: $txt-size-h7;
    position: absolute;
    right: -15px;
    text-align: center;
    line-height: 30px;
    background-color: #ffffff;
    @include border-radius(4px);
  }

  &__collapseservice {
    background-color: #F5F5F5;
    border: 1px solid #E9E9E9;
    padding: 3px;
    text-align: center;
    margin-bottom: 10px;
  }

  &--blue {
    height: 32px;
    //line-height: 32px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: $bg-color-blue!important;
    color: $txt-color-white;
    font-size: $txt-size-h7;
    font-weight: 400;
    border: 1px solid $bg-color-blue!important;

    &:hover {
      background-color: $bg-color-white!important;
      border: 1px solid $bg-color-blue!important;
      color: $txt-color-blue!important;
    }

    &:focus {
      background-color: $bg-color-blue !important;
      border: 1px solid $bg-color-blue;
      color: $txt-color-white !important;
    }
  }

  &__status {
    height: 22px;
    line-height: 22px;
    background-color: $bg-color-blue;
    font-size: $txt-size-h8;
    font-family: $robotofont;
    font-weight: 400;
    border: 0;
    color: $txt-color-white;
    @include border-radius(32px);
    padding: 0 16px;
  }

  &--log {
    background-color: #E8E8E8;
    border: 1px solid rgba(0, 0, 0, 0.25);
    color: rgba(0, 0, 0, 0.65);
  }
}

.ant-btn-primary {
  color: $txt-color-white;

  &:focus {
    background-color: #096DD9;
  }
}
